import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import useAuth from '@hooks/useAuth';

const IndexPage = () => {
    const { user, authReady } = useAuth();
    const router = useRouter();
    const hasRedirected = useRef(false);

    useEffect(() => {
        if (!router || !authReady || hasRedirected.current) return;
        hasRedirected.current = true;
        const targetPath = user ? '/labspace' : '/login';
        setTimeout(() => router.push(targetPath), 2000);
    }, [router, user, authReady]);

    return (
        <>
            <div className="animated-background">
                <div className="logo-container">
                    <div className="logo-glob" />
                    <img
                        src="https://cdn.bfldr.com/9AK17BV1/at/mtbkww8pqf34s7th3ffmgst/Email_-_Pluto_signature_spinning_logo"
                        alt="Loading Pluto"
                        width="400"
                    />
                </div>
            </div>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                .animated-background {
                    background: linear-gradient(220deg, #06141b, #341045, #0e1422, #06141b, #4b1590, #06141b);
                    background-size: 600% 600%;
                    animation: gradientFlow 20s ease infinite;
                    width: 100%;
                    height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }
                @keyframes gradientFlow {
                    0% {
                        background-position: 0% 50%;
                    }
                    50% {
                        background-position: 100% 50%;
                    }
                    100% {
                        background-position: 0% 50%;
                    }
                }
                .logo-container {
                    position: relative;
                    z-index: 2;
                }
                .logo-glob {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 450px;
                    height: 450px;
                    background: radial-gradient(circle, rgba(75, 21, 144, 0.4), rgba(75, 21, 144, 0));
                    filter: blur(70px);
                    z-index: 1;
                }
                img {
                    position: relative;
                    z-index: 2;
                }
            `}</style>
        </>
    );
};

export default IndexPage;
